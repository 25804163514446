const latinRegex = /[\p{scx=Latin}0-9⁰¹²³⁴-⁾₀-₎¢¤$€£¥+−×÷=≠><≥≤±≈~¬∅∞∫∏∑√∂%‰@&.,:;!¡?¿'"‘’“”\-(){}[\]|/\\^_`*#\s]+/gu;
const latinCommaRegex = /,/;
const latinPeriodRegex = /\./;
const openingsRegex = /（|［|｛|〔|〈|《|「|『|【|〘|〖|“|‘/;
const closingsRegex = /）|］|｝|〕|〉|》|」|』|】|〙|〗|”|’/;
const middleDotsRegex = /・|：|；/;
const commasRegex = /、|，/;
const periodsRegex = /。|．/;
const japaneseRegex = new RegExp(
  `[\\p{scx=Hiragana}\\p{scx=Katakana}|\uFF66-\uFF9F\\p{scx=Han}${openingsRegex.source}${closingsRegex.source}${middleDotsRegex.source}${commasRegex.source}${periodsRegex.source}]+`,
  "u"
);
const noBreakRulesRegex = new RegExp(
  `[${"\u2014\u2025\u2026\uFF3F"}${String.fromCharCode(9472)}-${String.fromCharCode(9599)}]+`,
  "g"
);
const whitespaceRegex = /[\s\u00A0]/;
const punctuationRegex = new RegExp(
  `${latinCommaRegex.source}|${latinPeriodRegex.source}|${openingsRegex.source}|${closingsRegex.source}|${middleDotsRegex.source}|${commasRegex.source}|${periodsRegex.source}`,
  "u"
);
export {
  closingsRegex,
  commasRegex,
  japaneseRegex,
  latinCommaRegex,
  latinPeriodRegex,
  latinRegex,
  middleDotsRegex,
  noBreakRulesRegex,
  openingsRegex,
  periodsRegex,
  punctuationRegex,
  whitespaceRegex
};
